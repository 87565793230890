import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AdminBodyItem from "../Containers/AdminBodyItem";
import Button from "../../components/common/button/Button";
import FlatTable from "../Components/FlatTable/FlatTable";
import { Tab, TabSize } from "../../components/common/tab/Tab";
import TableGrid from "../../components/common/table-grid/TableGrid";
import { twMerge } from "tailwind-merge";
import { IBetHistory, IReferralMetric, IReferralScheme, IReferralSchemeConfig, IReferralSettlement, IReferralStat, ITransactionHistory } from "../../utils/supabase/types";
import { useModal } from "react-modal-hook";
import { AuthenticateModal } from "../modals/referrals/AuthenticateModal";
import { DataCachingContext } from "../Contexts/DataCachingContext";
import { fetchAdminReferralSettlements, getAdminReferralConfig, getAdminReferralMetrics, getAdminReferralSchemes, getAdminReferralStats, loadAdminPlayerBets, loadAdminTxnHistory } from "../../utils/supabase/supabase";
import { ReferralConfigModal } from "../modals/referrals/ReferralConfigModal";
import { ReferralSchemeModal } from "../modals/referrals/ReferralSchemeModal";
import Input from "../../components/common/input/Input";

export const ReferralsPage = () => {
  // CHECK THE LOGGED IN WALLETS VS THE ADMIN WALLETS IN THE DB
  const { jwt } = useContext(DataCachingContext)
  
  // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
  const [showAuthenticateModal, hideAuthenticateModal] = useModal(
      ({ in: open }): JSX.Element => (
        <AuthenticateModal visible={open} hideModal={hideAuthenticateModal} />
      ),
      [],
    );
  const tabs = ["config", "schemes", "stats", "metrics"];
  const [currentTab, setCurrentTab] = useState<(typeof tabs)[number]>("config");
  
  // OVERALL REFERRAL CONFIG
  const [referralConfig, setReferralConfig] = useState<IReferralSchemeConfig>()
  const [referralSchemes, setReferralSchemes] = useState<IReferralScheme[]>()

  // USED FOR THE EDIT SCHEME MODAL
  const [selectedScheme, setSelectedScheme] = useState<IReferralScheme>()

  const referralConfigData = useMemo(
    () => [
      {
        title: "id",
        value: referralConfig?.id,
      },
      { title: "created at", value: referralConfig?.created_at },
      { title: "daySpreadForClaimRemainder", value: referralConfig?.daySpreadForClaimRemainder },
      { title: "defaultReferralRatePerThousand", value: referralConfig?.defaultReferralRatePerThousand },
      { title: "maxEnhancedReferralRatePerThousand", value: referralConfig?.maxEnhancedReferralRatePerThousand },
      { title: "minReferredPlayersToCollect", value: referralConfig?.minReferredPlayersToCollect },
      { title: "minReferredWagerValueToCollect", value: referralConfig?.minReferredWagerValueToCollect },
      { title: "maxReferralSchemesPerPlayer", value: referralConfig?.maxReferralSchemesPerPlayer },
      { title: "upFrontClaimPerThousand", value: referralConfig?.upFrontClaimPerThousand },
    ],
    [referralConfig],
  );

  const loadReferralConfig = useCallback(async () => {
    console.log(`LOADING THE CONFIG`)
    let payload: any = {
      ...jwt
    }

    try {
      const config = await getAdminReferralConfig(payload)
      console.log({
        config
      })
    
      setReferralConfig(config[0])
    } catch(err) {
      console.warn(`Issue loading referral config`, {err})
    }
  }, [jwt])

  useEffect(() => {
    // LOAD THE REFERRAL CONFIG
    async function loadReferralConfig(jwtPayload: {
      jwt: string
    }) {
      try {
        const referralConfigResp = await getAdminReferralConfig(jwtPayload)
      
        setReferralConfig(referralConfigResp[0])
      } catch(err) {
        console.warn(`Err loading referral config`, {
          err
        })
      }
    }

    if (jwt == null) {
      return
    }

    loadReferralConfig(jwt)
  }, [jwt])

  // USED FOR FILTERING REFERRAL SCHEMES
  const [referralId, setReferralId] = useState<string>()
  const [wallet, setWallet] = useState<string>()

  const loadReferralSchemes = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralSchemesResp = await getAdminReferralSchemes(payload)
    
      setReferralSchemes(referralSchemesResp)
    } catch(err) {
      console.warn(`Issue loading referral schemes`, {err})
    }
  }, [jwt, referralId, wallet])

  const [referralStats, setReferralStats] = useState<IReferralStat[]>()

  const loadReferralStats = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralStatsResp = await getAdminReferralStats(payload)
    
      setReferralStats(referralStatsResp)
    } catch (err) {
      console.warn(`Issue loadig referral stats`, {err})
    }
  }, [jwt, referralId, wallet])

  useEffect(() => {
    async function loadReferralSchemes(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralSchemesResp = await getAdminReferralSchemes(jwtPayload)
      
        setReferralSchemes(referralSchemesResp)
      } catch (err) {
        console.warn(`Err loafing schemes`, {err})
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralSchemes(payload)
  }, [jwt])

  useEffect(() => {
    async function loadReferralStats(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralStatsResp = await getAdminReferralStats(jwtPayload)
      
        setReferralStats(referralStatsResp)
      } catch (err) {
        console.log(`Err loading stats`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralStats(payload)
  }, [jwt])

  // LOAD REFERRAL METRICS
  const [referralMetrics, setReferralMetrics] = useState<IReferralMetric[]>()
  useEffect(() => {
    async function loadReferralMetrics(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralMetricsResp = await getAdminReferralMetrics(jwtPayload)
      
        setReferralMetrics(referralMetricsResp)
      } catch (err) {
        console.log(`Err loading metrics`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralMetrics(payload)
  }, [jwt])

  const loadReferralMetrics = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralMetricsResp = await getAdminReferralMetrics(payload)
    
      setReferralMetrics(referralMetricsResp)
    } catch(err) {
      console.warn(`Issue loading referral metrics`, {err})
    }
  }, [jwt, referralId, wallet])

  // LOAD DEPOSITS / WITHDRAWALS
  // LOAD REFERRAL METRICS
  const [deposits, setDeposits] = useState<ITransactionHistory[]>()
  const [withdrawals, setWithdrawals] = useState<ITransactionHistory[]>()

  useEffect(() => {
    console.log({
      deposits,
      withdrawals
    })
  }, [deposits, withdrawals])

  useEffect(() => {
    async function loadTxnHistory(jwtPayload: {
      jwt: string
    }) {
      try {
        const depositsResp = await loadAdminTxnHistory({...jwtPayload, includeDeposits: true, includeWithdrawals: false})
        const withdrawalsResp = await loadAdminTxnHistory({...jwtPayload, includeDeposits: false, includeWithdrawals: true})

      
        setWithdrawals(withdrawalsResp)
        setDeposits(depositsResp)
      } catch (err) {
        console.log(`Err loading metrics`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    loadTxnHistory(payload)
  }, [jwt])

  const loadDeposits = useCallback(async (wallet: string | undefined = undefined) => {
    // ADD ANY OTHER FILTERS HERE
    let payload: any = {
      ...jwt,
      includeDeposits: true,
      includeWithdrawals: false,
      wallet: wallet
    }

    try {
      const resp = await loadAdminTxnHistory(payload);

      setDeposits(resp)
    } catch(err) {
      console.warn(`Issue loading deposits`, {err})
    }
  }, [jwt, wallet])

  const loadWithdrawals = useCallback(async (wallet: string | undefined = undefined) => {
    // ADD ANY OTHER FILTERS HERE
    let payload: any = {
      ...jwt,
      includeDeposits: false,
      includeWithdrawals: true,
      wallet: wallet
    }

    try {
      const resp = await loadAdminTxnHistory(payload);

      setWithdrawals(resp)
    } catch(err) {
      console.warn(`Issue loading withdrawals`, {err})
    }
  }, [jwt, wallet])

  // LOAD DEPOSITS / WITHDRAWALS
  // LOAD REFERRAL METRICS
  const [bets, setBets] = useState<IBetHistory[]>()

  useEffect(() => {
    async function loadBetHistory(jwtPayload: {
      jwt: string
    }) {
      try {
        const betsResp = await loadAdminPlayerBets({...jwtPayload})
        setBets(betsResp)
      } catch (err) {
        console.log(`Err loading metrics`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    loadBetHistory(payload)
  }, [jwt])

  const loadBetHistory = useCallback(async (wallet: string | undefined = undefined) => {
    // ADD ANY OTHER FILTERS HERE
    let payload: any = {
      ...jwt,
      wallet: wallet
    }

    try {
      const resp = await loadAdminPlayerBets(payload);

      setBets(resp)
    } catch(err) {
      console.warn(`Issue loading bets`, {err})
    }
  }, [jwt, wallet])

  const [referralSettlements, setReferralSettlements] = useState<IReferralSettlement[]>()

  useEffect(() => {
    console.log({
      referralSettlements
    })
  }, [referralSettlements])
  
  useEffect(() => {
    async function loadReferralSettlements(jwtPayload: {
      jwt: string
      referralId?: string,
      wallet?: string
    }) {
      try {
        const referralSettlementsResp = await fetchAdminReferralSettlements(jwtPayload)
      
        setReferralSettlements(referralSettlementsResp)
      } catch (err) {
        console.log(`Err loading settlements`, {
          err
        })
      }
    }
    // LOAD THE REFERRAL SCHEMES
    if (jwt == null) {
      return
    }
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    loadReferralSettlements(payload)
  }, [jwt])

  const loadReferralSettlements = useCallback(async () => {
    let payload: any = {
      ...jwt
    }

    if (referralId != null && referralId.length > 0) {
      payload.referralId = referralId
    }

    if (wallet != null && wallet.length > 0) {
      payload.wallet = wallet
    }

    try {
      const referralSettlementsResp = await fetchAdminReferralSettlements(payload)
      
      setReferralSettlements(referralSettlementsResp)
    } catch(err) {
      console.warn(`Issue loading referral settlements`, {err})
    }
  }, [jwt, referralId, wallet])

   // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
   const [showReferralConfigModal, hideReferralConfigModal] = useModal(
    ({ in: open }): JSX.Element => (
      <ReferralConfigModal referralConfig={referralConfig} loadReferralConfig={loadReferralConfig} visible={open} hideModal={hideReferralConfigModal} />
    ),
    [referralConfig, loadReferralConfig],
  );

  // MODAL USED TO LOAD / REFRESH A JWT IF NEEDED
  const [showReferralSchemeModal, hideReferralSchemeModal] = useModal(
    ({ in: open }): JSX.Element => (
      <ReferralSchemeModal referralScheme={selectedScheme} reloadSchemes={loadReferralSchemes} visible={open} hideModal={hideReferralSchemeModal} />
    ),
    [selectedScheme, loadReferralSchemes],
  );

  const tabContent = () => {
    // TODO - ACTIONS ONCE HOUSE ACTIONS ARE THERE
    if (currentTab === "config") {
      return (
        <>
          <AdminBodyItem title="Referral Config">
            <div className="flex justify-end items-center gap-x-2">
              <Button disabled={jwt == null || referralConfig == null} variant="secondary" onClick={showReferralConfigModal}>
                Change Config
              </Button>
              <Button variant="secondary" onClick={showAuthenticateModal}>
                Authenticate
              </Button>
            </div>
          </AdminBodyItem>
          <AdminBodyItem
            title="Referral Config"
          >
            <FlatTable data={referralConfigData} />
          </AdminBodyItem>
        </>
      );
    }
    if (currentTab === "schemes") {
      const formattedSchemeRows = (referralSchemes || [])?.map((scheme) => {
        return (
          {
            identifier: scheme.identifier,
            wallet: scheme.wallet,
            accrualExpiryDate: scheme.accrualExpiryDate,
            bespokeTerms: scheme.bespokeTerms,
            daySpreadForClaimRemainder: scheme.daySpreadForClaimRemainder,
            defaultReferralRatePerThousand: scheme.defaultReferralRatePerThousand,
            enhancedAccrualExpiryDate: scheme.enhancedAccrualExpiryDate,
            enhancedReferralRatePerThousand: scheme.enhancedReferralRatePerThousand,
            minReferredPlayersToCollect: scheme.minReferredPlayersToCollect,
            minReferredWagerValueToCollect: scheme.minReferredWagerValueToCollect,
            status: scheme.status,
            upFrontClaimPerThousand: scheme.upFrontClaimPerThousand,
            edit: (
              <div className="flex gap-3">
                <Button
                  onClick={() => {
                    setSelectedScheme(scheme)
                    showReferralSchemeModal()
                   // TODO - SET SCHEME
                   // OPEN MODAL
                  }}
                >
                  Change Scheme
                </Button>
              </div>

            )
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Referral Schemes"
          titleSuffix={<span className="text-gray-300 text-xl">{referralSchemes?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={wallet} onChange={(e) => {
                        setWallet(e.target.value);
                    }} placeholder="Wallet Pubkey" />
              <Button onClick={loadReferralSchemes}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedSchemeRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "wallet",
                accessor: "wallet",
              },
              {
                header: "accrualExpiryDate",
                accessor: "accrualExpiryDate",
              },
              {
                header: "bespokeTerms",
                accessor: "bespokeTerms",
              },
              {
                header: "daySpreadForClaimRemainder",
                accessor: "daySpreadForClaimRemainder",
              },
              {
                header: "defaultReferralRatePerThousand",
                accessor: "defaultReferralRatePerThousand",
              },
              {
                header: "enhancedAccrualExpiryDate",
                accessor: "enhancedAccrualExpiryDate",
              },
              {
                header: "enhancedReferralRatePerThousand",
                accessor: "enhancedReferralRatePerThousand",
              },
              {
                header: "minReferredPlayersToCollect",
                accessor: "minReferredPlayersToCollect",
              },
              {
                header: "minReferredWagerValueToCollect",
                accessor: "minReferredWagerValueToCollect",
              },
              {
                header: "status",
                accessor: "status",
              },
              {
                header: "upFrontClaimPerThousand",
                accessor: "upFrontClaimPerThousand",
              },
              {
                header: "Edit",
                accessor: "edit",
                classes: " right-0 bg-gray-800"
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Schemes"
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "stats") {
      const formattedStatRows = (referralStats || [])?.map((stat) => {
        return (
          {
            identifier: stat.identifier,
            referredPlayers: stat.referredPlayers,
            totalBets: stat.totalBets,
            totalReferralCommissionAccrued: stat.totalReferralCommissionAccrued,
            totalReferralCommissionDrawndown: stat.totalReferralCommissionDrawndown,
            totalWagered: stat.totalWagered
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Referral Schemes"
          titleSuffix={<span className="text-gray-300 text-xl">{referralSchemes?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={wallet} onChange={(e) => {
                        setWallet(e.target.value);
                    }} placeholder="Wallet Pubkey" />
              <Button onClick={loadReferralStats}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedStatRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "referredPlayers",
                accessor: "referredPlayers",
              },
              {
                header: "totalBets",
                accessor: "totalBets",
              },
              {
                header: "totalReferralCommissionAccrued",
                accessor: "totalReferralCommissionAccrued",
              },
              {
                header: "totalReferralCommissionDrawndown",
                accessor: "totalReferralCommissionDrawndown",
              },
              {
                header: "totalWagered",
                accessor: "totalWagered",
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Stats"
          />
        </AdminBodyItem>
      )
    }
    if (currentTab === "metrics") {
      const formattedMetricsRows = (referralMetrics || [])?.map((metric) => {
        return (
          {
            identifier: metric.referralId,
            chain: metric.chain,
            countPlayers: metric.countPlayers,
            date: metric.date,
            platform: metric.platform,
            totalExpectedEdge: metric.totalExpectedEdge,
            totalExpectedReferralCommission: metric.totalExpectedReferralCommission,
            totalLossCount: metric.totalLossCount,
            totalPaidOut: metric.totalPaidOut,
            totalWagered: metric.totalWagered,
            pnl: metric.totalPaidOut - metric.totalWagered,
            totalWinCount: metric.totalWinCount
          }
        )
      });

      const commonCellClasses = 'text-sm border-gray-500 bg-none! first:rounded-l-none last:rounded-r-none';

      return (
        <AdminBodyItem
          title="Daily Referral Metrics"
          titleSuffix={<span className="text-gray-300 text-xl">{referralMetrics?.length}</span>}
          rightHeaderContent={
            <div className="flex basis-0 gap-x-2">
              <Input classes={{ wrapper: "min-w-[150px]" }} variant="regular" type="text" value={referralId} onChange={(e) => {
                        setReferralId(e.target.value);
                    }} placeholder="Referral Id" />
              <Button onClick={loadReferralMetrics}>Load</Button>

              <Button  onClick={showAuthenticateModal}>Authenticate</Button>
            </div>
          }
        >
          <TableGrid
            rows={formattedMetricsRows}
            classes={{ table: 'gap-y-0', bodyCell: twMerge(commonCellClasses, "h-[60px] font-bold"), headerCell: twMerge(commonCellClasses, "max-h-[60px]") }}
            columns={[
              {
                header: "Date",
                accessor: "date",
              },
              {
                header: "identifier",
                accessor: "identifier",
              },
              {
                header: "count players",
                accessor: "countPlayers",
              },
              {
                header: "total payout",
                accessor: "totalPaidOut",
              },
              {
                header: "total wagered",
                accessor: "totalWagered",
              },
              {
                header: "total pnl",
                accessor: "pnl",
              },
            ]}
            variant="regular"
            emptyMessage="No Referral Metrics"
          />
        </AdminBodyItem>
      )
    }
  };

  return (
    <>
      <div className="flex gap-4">
        {tabs.map((tab, index) => (
          <Tab
            size={TabSize.MD}
            active={currentTab === tab}
            onClick={() => setCurrentTab(tab)}
            extraClasses="capitalize"
          >
            {tab}
          </Tab>
        ))}
      </div>
      {tabContent()}
    </>
  );
};
