import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { SignerContext } from "../../Contexts/SignersContext";
import { DataCachingContext } from "../../Contexts/DataCachingContext";
import { useWallet } from "@solana/wallet-adapter-react";

interface IAuthenticateModalProps {
    visible: boolean;
    hideModal: Function;
}

// MODAL FOR AUTHENTICATING THE REFERRALS PAGE
export const AuthenticateModal: FC<IAuthenticateModalProps> = ({ visible, hideModal }) => {
    const { signerInput, signerInputError, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput, signers } = useContext(SignerContext);
    const { loadJwt, adminWallets } = useContext(DataCachingContext)
    const { publicKey, signMessage } = useWallet()
    const noPermissions = useMemo(() => {
        if (connectedViaWallet) {
            const hasWallet = adminWallets.find((wallet) => {
                return wallet.pubkey == publicKey?.toString()
            })

            return hasWallet == null
        } else if (connectedViaKeyPair) {
            const adminPubkeys = adminWallets.map((wall) => {
                return wall.pubkey
            })
            const signerPubkeys = signers.map((signe) => {
                return signe.publicKey.toString()
            })
            const hasWallet = adminPubkeys.find((wallet) => {
                return signerPubkeys.includes(wallet)
            })

            return hasWallet == null
        }

        return true
    }, [adminWallets, publicKey, keypair, connectedViaWallet, connectedViaKeyPair])

    // CLEAR INPUTS WHEN MODAL LOADS
    useEffect(() => {
        setPinInput(undefined);
    }, [visible])

    const noPermissionsContent = useMemo(() => {
        return (
            <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
                <div className="font-white text-3xl font-oxanium">No Permissions</div>
                <div className="text-xl text-gray-300">
                    No permissions have been granted to selected wallet. Please contact Zeebit’s team when
                    requesting DevTools access.
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        // WIPE ALL THE INPUTS
                        // emptyInputs();
                    }}
                >
                    Try Another Wallet
                </Button>
            </div>
        );
    }, []);

    const { client } = useContext(NetworkContext)
    const [loading, setLoading] = useState(false)

    const authenticateWallet = useCallback(async () => {
        setLoading(true)

        try {

            await loadJwt(
                connectedViaWallet == true ? publicKey : undefined,
                connectedViaWallet == true ? signMessage : undefined,
                connectedViaKeyPair == true ? keypair : undefined
            )

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue updating house status', err)

        }

        setLoading(false)
    }, [selectedSigner, pinInput, client, connectedViaWallet, connectedViaKeyPair, keypair, signerInputError, loadJwt, publicKey, signMessage])

    return (
        <BaseModal open={visible} onClose={hideModal} title="Authentication">
            <div className="max-h-[400px] flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions ? noPermissionsContent : ""}
                {!noPermissions ? (
                    <div className="flex flex-col items-center gap-y-4 w-full">

                        {/* Wallet */}
                        {signerInput}

                        {/* AUTH WALLET BUTTON */}
                        <FormItem
                            error={
                                noPermissions ? "Selected wallet does not have permission for this action" : ""
                            }
                            className="flex flex-col gap-1 flex-1 self-stretch"
                        >
                            <Button
                                isLoading={loading}
                                disabled={signerInputError || client == null}
                                onClick={authenticateWallet}
                                variant="secondary"
                            >
                                Authenticate Wallet
                            </Button>
                        </FormItem>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </BaseModal>
    );
};
