import { Fragment, useContext, useMemo } from "react";
import { SidebarDesktop } from "../../../components/left-nav/SideBarDesktop";
import { useLocation, useNavigate } from "react-router-dom";
import { SCROLLBAR_CLASSES_BLACK } from "../../../styles/commonClasses";
import NavItem from "../../../components/common/navigation-item/NavItem";
import { LpContext } from "../../Contexts/LiquidityProviderContext";
import { TokenAccessContext } from "../../Contexts/TokenAccessProvider";
import { DataCachingContext } from "../../Contexts/DataCachingContext";

export function LeftNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin: isTokenAdmin } = useContext(TokenAccessContext);
  const { isAdmin: isLpAdmin } = useContext(LpContext);
  const { hasAdminWallet } = useContext(DataCachingContext);

  const activePage = useMemo(() => {
    if (location == null) {
      return;
    }
    return location.pathname;
  }, [location]);
  const OPTIONS = [
    {
      icon: "show",
      name: "General",
      key: "",
      onClick: () => {
        navigate(``);
      },
    },
    isTokenAdmin ?
      {
        icon: "home",
        name: "House & Tokens",
        key: "house",
        onClick: () => {
          navigate(`house`);
        },
      } : null,
    isLpAdmin ?
      {
        icon: "coins",
        name: "Liquidity Provision",
        key: "liquidity",
        disabled: false,
        onClick: () => {
          navigate(`liquidity`);
        },
      } : null,
      hasAdminWallet ?
      {
        icon: "home",
        name: "Referrals",
        key: "referrals",
        onClick: () => {
          navigate(`referrals`);
        },
      } : null,
    {
      icon: "play",
      name: "Nft Staking",
      key: "nft-staking",
      disabled: false,
      onClick: () => {
        navigate(`nft-staking`);
      },
    },
    isTokenAdmin ? {
      icon: "play",
      name: "Games",
      key: "games",
      disabled: false,
      onClick: () => {
        navigate(`games`);
      },
    } : null,
  ];
  return (
    <Fragment>
      {/* Static sidebar for desktop */}
      <SidebarDesktop leftBarIsOpen={true}>
        <div className={`flex grow-0 md:grow flex-col md:overflow-y-auto ${SCROLLBAR_CLASSES_BLACK}`}>
          <nav className={`flex flex-col mb-2 mr-2`}>
            <ul role="list" className="flex flex-1 flex-col gap-y-1">
              {OPTIONS.map((option, index) => {
                if (!option) return;
                return (
                  <NavItem
                    key={index}
                    isSelected={activePage != null && activePage == option.key}
                    isTopLevel
                    iconName={option.icon}
                    collapsed={false}
                    title={option.name}
                    onClick={option.onClick}
                    disabled={option.disabled}
                  />
                );
              })}
            </ul>
          </nav>
        </div>
      </SidebarDesktop>
    </Fragment>
  );
}
