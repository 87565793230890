import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import base58 from "bs58";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { createReferralScheme, getAdminWallets, getJwt, updateAdminReferralConfig, updateAdminReferralScheme, validateReferralIdentifier } from "../../utils/supabase/supabase";
import { IReferralScheme, IReferralSchemeConfig } from "../../utils/supabase/types";
import { Keypair, PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../../utils/solana/rpc";
import { SignerContext } from "./SignersContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { sign } from 'tweetnacl'

interface IJwt {
    jwt: string
    expiry: Date
    walletPubkey: string
}

export interface IDataCachingContext {
    jwt: IJwt | undefined
    loadJwt: (wallet?: PublicKey, 
        signMessage?: ((message: Uint8Array) => Promise<Uint8Array>) | undefined,
        keypair?: Keypair) => Promise<IJwt>
    validateReferralId: (referralId: string, wallet?: PublicKey, rpc?: ISolanaRpc) => Promise<boolean>,
    hasAdminWallet: boolean,
    adminWallets: {
        pubkey: string
    }[],
    updateReferralConfig: (referralConfig?: IReferralSchemeConfig, wallet?: PublicKey, signMessage?: ((message: Uint8Array<ArrayBufferLike>) => Promise<Uint8Array<ArrayBufferLike>>) | undefined, keypair?: Keypair) => Promise<IReferralSchemeConfig[]>,
    updateReferralScheme: (referralScheme?: IReferralScheme, wallet?: PublicKey, signMessage?: ((message: Uint8Array<ArrayBufferLike>) => Promise<Uint8Array<ArrayBufferLike>>) | undefined, keypair?: Keypair) => Promise<IReferralSchemeConfig[]>
}

export const DataCachingContext = createContext<IDataCachingContext>({} as IDataCachingContext);

interface Props {
    children: any;
}

export const DataCachingProvider = ({ children }: Props) => {
    // JWT TO BE USED FOR ACTIONS
    const [jwt, setJwt] = useLocalStorage('zeebit-caching-acccess', undefined)

    // POSSIBLE SIGNERS
    const { signers } = useContext(SignerContext);
    const { publicKey } = useWallet();

    const [adminWallets, setAdminWallets] = useState<{
        pubkey: string
    }[]>()

    // SAMPLE FUNCTION TO CHECK IF ANY WALLETS CONNECTED HAVE ADMIN PERMISSIONS
    const hasAdminWallet = useMemo(() => {
        if (adminWallets == null || adminWallets.length == 0) {
            return false
        }

        if ((signers == null || signers.length == 0) && publicKey == null) {
            return false
        }

        const signedInWallets: string[] = []
        signers.forEach((signer) => {
            signedInWallets.push(signer.publicKey)
        })
        if (publicKey != null) {
            signedInWallets.push(publicKey.toString())
        }

        const hasWallet = adminWallets.find((wallet) => {
            return signedInWallets.includes(wallet.pubkey)
        })

        return hasWallet != null
    }, [adminWallets, signers, publicKey])
    
    useEffect(() => {
        // LOAD AUTH WALLETS
        async function loadAuthWallets() {
            try {
                const adminWalletsResp = await getAdminWallets()
                setAdminWallets(adminWalletsResp)
            } catch (err) {
                console.warn(`Issue loading the auth wallets ${err}`)
            }
        }

        loadAuthWallets()
    }, [])

    useEffect(() => {
        const expiryDate = jwt != null ? new Date(jwt.expiry): undefined
        const hasExpiredJwt = (!!expiryDate && (expiryDate < new Date()))

        if (hasExpiredJwt) {
            setJwt(undefined)
        }
    }, [jwt])

    const loadJwt = useCallback(async (
        // YOU CAN EITHER PASS THE WALLET PUBKEY, AND SIGN METHOD (BROWSER) OR KEYPAIR
        wallet?: PublicKey, 
        signMessage?: ((message: Uint8Array) => Promise<Uint8Array>) | undefined,
        keypair?: Keypair
    ): Promise<IJwt> => {

        if ((wallet == null || signMessage == null) && keypair == null) {
            throw new Error("Wallet pubkey null, or solana client issue when loading access.")
        }

        const usingKeypair = keypair != null
        const walletToCompare = usingKeypair == true ? keypair.publicKey: wallet

        const currentTime = new Date()

        const jwtMeta = jwt || {}
        const expiryDate = jwt != null ? new Date(jwt.expiry): undefined
        const hasExpiredJwt = (!!expiryDate && (expiryDate < currentTime))
        const hasDifferentWallet = jwtMeta.walletPubkey != walletToCompare?.toString()
        const needsJwtUpdate = jwt == null || hasExpiredJwt || hasDifferentWallet

        if (needsJwtUpdate) {
            const currentTime = Math.floor(Date.now() / 1000);

            const message = `sign in at: ${currentTime}`;
            const encodedMessage = new TextEncoder().encode(message)

            const signature = usingKeypair ? sign.detached(encodedMessage, keypair.secretKey): await signMessage!(new TextEncoder().encode(message));

            const encodedSignature = base58.encode(signature);

            const newJwt = await getJwt({
                message,
                signature: encodedSignature,
                wallet: walletToCompare?.toBase58(),
            })
            const expiry = new Date()
            expiry.setHours(expiry.getHours() + 1)
            const updatedJwt = {
                jwt: newJwt.jwt,
                expiry: expiry,
                walletPubkey: walletToCompare?.toString()
            }

            setJwt(updatedJwt)

            return updatedJwt
        }

        return jwtMeta
    }, [jwt, setJwt])

    // VALIDATE REFERRAL SCHEME
    const validateReferralId = useCallback(async (referralIdentifier: string, wallet: PublicKey, rpc: ISolanaRpc): Promise<boolean> => {

        if (wallet == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A JWT is needed to interact with the API.")
            }

            const dataPayload = {
                wallet: wallet.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }

            try {
                const validated = await validateReferralIdentifier(dataPayload);
                
                return true
            } catch (err) {
                return false
            }
        } catch (err) {
            console.error("Issue validating the referral id", err)
            return false
        }
    }, [jwt, loadJwt])

    // CREATE REFERRAL SCHEME
    const createReferral = useCallback(async (referralIdentifier: string, wallet: PublicKey, rpc: ISolanaRpc): Promise<IReferralScheme> => {
        if (wallet == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to create a referral scheme.")
            }

            const dataPayload = {
                wallet: wallet.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }


            return await createReferralScheme(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [jwt, loadJwt])

    const updateReferralConfig = useCallback(async (
        referralConfig?: IReferralSchemeConfig,
        wallet?: PublicKey,
        signMessage?: ((message: Uint8Array<ArrayBufferLike>) => Promise<Uint8Array<ArrayBufferLike>>) | undefined,
        keypair?: Keypair
    ): Promise<IReferralSchemeConfig[]> => {

        if (hasAdminWallet == false) {
            throw new Error('Please connect an admin wallet, and try again.')
        }

        
        // NEED A JWT TO CALL THE API
        const jwt = await loadJwt(wallet, signMessage, keypair)

        if (jwt == null) {
            throw new Error("A JWT is needed to interact with the API.")
        }

        const dataPayload = {
            ...referralConfig,
            jwt: jwt.jwt
        }

        
        const updated = await updateAdminReferralConfig(dataPayload);
        
        return updated
        
    }, [jwt, loadJwt])

    const updateReferralScheme = useCallback(async (
        referralScheme?: IReferralScheme,
        wallet?: PublicKey,
        signMessage?: ((message: Uint8Array<ArrayBufferLike>) => Promise<Uint8Array<ArrayBufferLike>>) | undefined,
        keypair?: Keypair
    ): Promise<IReferralSchemeConfig[]> => {

        if (hasAdminWallet == false) {
            throw new Error('Please connect an admin wallet, and try again.')
        }

        
        // NEED A JWT TO CALL THE API
        const jwt = await loadJwt(wallet, signMessage, keypair)

        if (jwt == null) {
            throw new Error("A JWT is needed to interact with the API.")
        }

        const dataPayload = {
            ...referralScheme,
            jwt: jwt.jwt
        }

        
        const updated = await updateAdminReferralScheme(dataPayload);
        
        return updated
        
    }, [jwt, loadJwt])
    
    return (
        <DataCachingContext.Provider
            value={useMemo(
                () => ({
                    jwt: jwt,
                    loadJwt: loadJwt,
                    validateReferralId: validateReferralId,
                    createReferral: createReferral,
                    hasAdminWallet: hasAdminWallet,
                    adminWallets: adminWallets,
                    updateReferralConfig: updateReferralConfig,
                    updateReferralScheme: updateReferralScheme
                }),
                [jwt, loadJwt, validateReferralId, createReferral, hasAdminWallet, adminWallets, updateReferralConfig, updateReferralScheme],
            )}
        >
            {children}
        </DataCachingContext.Provider>
    );
};
