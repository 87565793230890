import React, { FC } from 'react';
import { IconSizes } from '../../../components/common';
import Icon from '../../../components/common/icon/Icon';
import IconButton from '../../../components/common/button/IconButton';
import { truncatePubkey } from '../../../utils/string/string';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

export interface PubkeyProps {
    pubkey: string;
    iconUrl?: string;
    iconSize?: IconSizes;
    buttonIconSize?: IconSizes;
    truncateSize?: number;
}

const Pubkey: FC<PubkeyProps> = ({ iconUrl, pubkey, iconSize, buttonIconSize, truncateSize }) => {
    const { copyToClipboard } = useCopyToClipboard("Copied to clipboard");
    if (!pubkey) return;
    return (
        <div className="flex gap-1.5 items-center">
            {iconUrl ? <Icon iconUrl={iconUrl} size={iconSize} /> : null}
            <div className="font-bold text-sm">{truncatePubkey(pubkey, truncateSize || 5)}</div>
            < IconButton
                size="xs"
                iconSize={buttonIconSize || "xs"}
                iconName="copy"
                className="bg-transparent"
                iconClass="text-gray-400"
                onClick={() => copyToClipboard(pubkey)}
            />
        </div>
    );
};

export default Pubkey;