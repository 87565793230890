import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { SignerContext } from "../../Contexts/SignersContext";
import { DataCachingContext } from "../../Contexts/DataCachingContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { IReferralSchemeConfig } from "../../../utils/supabase/types";
import Input from "../../../components/common/input/Input";

interface IReferralConfigModalProps {
    visible: boolean;
    hideModal: Function;
    referralConfig: IReferralSchemeConfig
    loadReferralConfig: () => Promise<void>
}

export const ReferralConfigModal: FC<IReferralConfigModalProps> = ({ visible, hideModal, referralConfig, loadReferralConfig }) => {
    const { signerInput, signerInputError, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput, signers } = useContext(SignerContext);
    const { adminWallets, jwt, updateReferralConfig } = useContext(DataCachingContext)
    const { publicKey, signMessage } = useWallet()

    const noPermissions = useMemo(() => {
        if (connectedViaWallet) {
            const hasWallet = adminWallets.find((wallet) => {
                return wallet.pubkey == publicKey?.toString()
            })

            return hasWallet == null
        } else if (connectedViaKeyPair) {
            const adminPubkeys = adminWallets.map((wall) => {
                return wall.pubkey
            })
            const signerPubkeys = signers.map((signe) => {
                return signe.publicKey.toString()
            })
            const hasWallet = adminPubkeys.find((wallet) => {
                return signerPubkeys.includes(wallet)
            })

            return hasWallet == null
        }

        return true
    }, [adminWallets, publicKey, keypair, connectedViaWallet, connectedViaKeyPair])

    // CLEAR INPUTS WHEN MODAL LOADS
    useEffect(() => {
        setPinInput(undefined);
    }, [visible])

    const noPermissionsContent = useMemo(() => {
        return (
            <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
                <div className="font-white text-3xl font-oxanium">No Permissions</div>
                <div className="text-xl text-gray-300">
                    No permissions have been granted to selected wallet. Please contact Zeebit’s team when
                    requesting DevTools access.
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        // WIPE ALL THE INPUTS
                        // emptyInputs();
                    }}
                >
                    Try Another Wallet
                </Button>
            </div>
        );
    }, []);

    const { client } = useContext(NetworkContext)
    const [loading, setLoading] = useState(false)

    const [updatedReferralConfig, setUpdatedReferralConfig] = useState<IReferralSchemeConfig>()
    useEffect(() => {
        setUpdatedReferralConfig(referralConfig)
    }, [referralConfig])

    const updateConfig = useCallback(async () => {
        setLoading(true)

        try {
            console.log({
                updatedReferralConfig
            })
            const updated = await updateReferralConfig(
                {
                    ...updatedReferralConfig
                },
                publicKey,
                signMessage,
                keypair
            )
            console.log({
                updated
            })

            // AFTER UPDATE ON DB, UPDATE STATE
            await loadReferralConfig()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue updating house status', err)

        }

        setLoading(false)
    }, [selectedSigner, pinInput, client, connectedViaWallet, connectedViaKeyPair, keypair, signerInputError, publicKey, signMessage, updateReferralConfig, loadReferralConfig, updatedReferralConfig])

    return (
        <BaseModal open={visible} onClose={hideModal} title="Referral Config">
            <div className="max-h-[500px] flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions ? noPermissionsContent : ""}
                {!noPermissions ? (
                    <div className="flex flex-col items-center gap-y-4 w-full m-t-4">
                         <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>daySpreadForClaimRemainder</span>
                            <Input
                            name="daySpreadForClaimRemainder"
                            variant="regular"
                            value={updatedReferralConfig?.daySpreadForClaimRemainder || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    daySpreadForClaimRemainder: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>defaultReferralRatePerThousand</span>
                            <Input
                            name="defaultReferralRatePerThousand"
                            variant="regular"
                            value={updatedReferralConfig?.defaultReferralRatePerThousand || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    defaultReferralRatePerThousand: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>maxEnhancedReferralRatePerThousand</span>
                            <Input
                            name="maxEnhancedReferralRatePerThousand"
                            variant="regular"
                            value={updatedReferralConfig?.maxEnhancedReferralRatePerThousand || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    maxEnhancedReferralRatePerThousand: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>minReferredPlayersToCollect</span>
                            <Input
                            name="minReferredPlayersToCollect"
                            variant="regular"
                            value={updatedReferralConfig?.minReferredPlayersToCollect || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    minReferredPlayersToCollect: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>minReferredWagerValueToCollect</span>
                            <Input
                            name="minReferredWagerValueToCollect"
                            variant="regular"
                            value={updatedReferralConfig?.minReferredWagerValueToCollect || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    minReferredWagerValueToCollect: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>maxReferralSchemesPerPlayer</span>
                            <Input
                            name="maxReferralSchemesPerPlayer"
                            variant="regular"
                            value={updatedReferralConfig?.maxReferralSchemesPerPlayer || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    maxReferralSchemesPerPlayer: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>upFrontClaimPerThousand</span>
                            <Input
                            name="upFrontClaimPerThousand"
                            variant="regular"
                            value={updatedReferralConfig?.upFrontClaimPerThousand || 0}
                            onChange={(e) => {
                                if (updatedReferralConfig == null) {
                                    return
                                }
                                setUpdatedReferralConfig({
                                    ...updatedReferralConfig,
                                    upFrontClaimPerThousand: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        {/* Wallet */}
                        {signerInput}

                        {/* AUTH WALLET BUTTON */}
                        <FormItem
                            error={
                                jwt == null ? "Please refresh/load a jwt for this action." : ""
                            }
                            className="flex flex-col gap-1 flex-1 self-stretch"
                        >
                            <Button
                                isLoading={loading}
                                disabled={signerInputError || client == null}
                                onClick={updateConfig}
                                variant="secondary"
                            >
                                Update Referral Config
                            </Button>
                        </FormItem>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </BaseModal>
    );
};
