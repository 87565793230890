import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { SignerContext } from "../../Contexts/SignersContext";
import { DataCachingContext } from "../../Contexts/DataCachingContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { IReferralScheme } from "../../../utils/supabase/types";
import Input from "../../../components/common/input/Input";

interface IReferralSchemeModalProps {
    visible: boolean;
    hideModal: Function;
    referralScheme: IReferralScheme
    reloadSchemes: () => Promise<void>
}

export const ReferralSchemeModal: FC<IReferralSchemeModalProps> = ({ visible, hideModal, referralScheme, reloadSchemes }) => {
    const { signerInput, signerInputError, keypair, pinInput, selectedSigner, connectedViaWallet, connectedViaKeyPair, setPinInput, signers } = useContext(SignerContext);
    const { adminWallets, jwt, updateReferralScheme } = useContext(DataCachingContext)
    const { publicKey, signMessage } = useWallet()

    const noPermissions = useMemo(() => {
        if (connectedViaWallet) {
            const hasWallet = adminWallets.find((wallet) => {
                return wallet.pubkey == publicKey?.toString()
            })

            return hasWallet == null
        } else if (connectedViaKeyPair) {
            const adminPubkeys = adminWallets.map((wall) => {
                return wall.pubkey
            })
            const signerPubkeys = signers.map((signe) => {
                return signe.publicKey.toString()
            })
            const hasWallet = adminPubkeys.find((wallet) => {
                return signerPubkeys.includes(wallet)
            })

            return hasWallet == null
        }

        return true
    }, [adminWallets, publicKey, keypair, connectedViaWallet, connectedViaKeyPair])

    // CLEAR INPUTS WHEN MODAL LOADS
    useEffect(() => {
        setPinInput(undefined);
    }, [visible])

    const noPermissionsContent = useMemo(() => {
        return (
            <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
                <div className="font-white text-3xl font-oxanium">No Permissions</div>
                <div className="text-xl text-gray-300">
                    No permissions have been granted to selected wallet. Please contact Zeebit’s team when
                    requesting DevTools access.
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        // WIPE ALL THE INPUTS
                        // emptyInputs();
                    }}
                >
                    Try Another Wallet
                </Button>
            </div>
        );
    }, []);

    const { client } = useContext(NetworkContext)
    const [loading, setLoading] = useState(false)

    const [updatedReferralScheme, setUpdatedReferralScheme] = useState<IReferralScheme>()
    useEffect(() => {
        setUpdatedReferralScheme(referralScheme)
    }, [referralScheme])

    const updateScheme = useCallback(async () => {
        setLoading(true)

        try {
            const updated = await updateReferralScheme(
                {
                    ...updatedReferralScheme
                },
                publicKey,
                signMessage,
                keypair
            )

            // AFTER UPDATE ON DB, UPDATE STATE
            await reloadSchemes()

            // CLEAR PIN AND SIGNER
            setPinInput(undefined)

            hideModal()
        } catch (err) {
            console.error('Issue updating referral scheme', err)

        }

        setLoading(false)
    }, [selectedSigner, pinInput, client, connectedViaWallet, connectedViaKeyPair, keypair, signerInputError, publicKey, signMessage, updateReferralScheme, reloadSchemes, updatedReferralScheme])

    return (
        <BaseModal open={visible} onClose={hideModal} title={`Referral Config (${updatedReferralScheme?.identifier})`}>
            <div className="max-h-[500px] flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions ? noPermissionsContent : ""}
                {!noPermissions ? (
                    <div className="flex flex-col items-center gap-y-4 w-full m-t-4">
                         <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>enhancedAccrualExpiryDate</span>
                            <Input
                            name="enhancedAccrualExpiryDate"
                            variant="regular"
                            value={updatedReferralScheme?.enhancedAccrualExpiryDate}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }

                                console.log({
                                    value: e.target.value
                                })
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    enhancedAccrualExpiryDate: e.target.value
                                })
                            }}
                            type="datetime-local"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>enhancedReferralRatePerThousand</span>
                            <Input
                            name="enhancedReferralRatePerThousand"
                            variant="regular"
                            value={updatedReferralScheme?.enhancedReferralRatePerThousand || 0}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    enhancedReferralRatePerThousand: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>minReferredPlayersToCollect</span>
                            <Input
                            name="minReferredPlayersToCollect"
                            variant="regular"
                            value={updatedReferralScheme?.minReferredPlayersToCollect || 0}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    minReferredPlayersToCollect: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>minReferredWagerValueToCollect</span>
                            <Input
                            name="minReferredWagerValueToCollect"
                            variant="regular"
                            value={updatedReferralScheme?.minReferredWagerValueToCollect || 0}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    minReferredWagerValueToCollect: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>

                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>status</span>
                            <Input
                            name="status"
                            variant="regular"
                            value={updatedReferralScheme?.status || 0}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    status: e.target.value
                                })
                            }}
                            type="text"
                            />
                        </div>


                        <div className="flex text-gray-50 justify-between w-full items-center gap-x-2">
                            <span>upFrontClaimPerThousand</span>
                            <Input
                            name="upFrontClaimPerThousand"
                            variant="regular"
                            value={updatedReferralScheme?.upFrontClaimPerThousand || 0}
                            onChange={(e) => {
                                if (updatedReferralScheme == null) {
                                    return
                                }
                                setUpdatedReferralScheme({
                                    ...updatedReferralScheme,
                                    upFrontClaimPerThousand: Number(e.target.value)
                                })
                            }}
                            type="text"
                            />
                        </div>


                        {/* Wallet */}
                        {signerInput}

                        {/* AUTH WALLET BUTTON */}
                        <FormItem
                            error={
                                jwt == null ? "Please refresh/load a jwt for this action." : ""
                            }
                            className="flex flex-col gap-1 flex-1 self-stretch"
                        >
                            <Button
                                isLoading={loading}
                                disabled={signerInputError || client == null}
                                onClick={updateScheme}
                                variant="secondary"
                            >
                                Update Referral Scheme
                            </Button>
                        </FormItem>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </BaseModal>
    );
};
